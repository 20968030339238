import { CV } from './types';

export const cvData: CV = {
    summary: [
        '🤓 Director of People, Talent & Internal Ops at Patchwork Health and consultant / advisor working with various start-ups and charities.',
        '🚀 Experienced Internal Ops leader and People and Talent enthusiast with a background in business management and infrastructure (People, Talent, Tech Ops & InfoSec, business continuity, H&S, and risk and change management). I love helping businesses identify and translate pain-points into scalable solutions.',
        '😎 Challenging "traditional" HR by promoting disruptive, agile and creative workplace experiences. Passionate about building great teams and cultures of high-performance, fun and empowerment. I love working with leaders to keep their high performing teams engaged - my speciality is doing this in remote and hybrid environments. Experience in designing solutions for sustainable growth and evolving to meet the needs of the business and its people. I love proving that a great People and Talent Team can make and save money 🤑',
        '🪄Experienced internal ops leader with a background in most aspects of business management and infrastructure (tech ops & InfoSec, business continuity, H&S, risk and change management and more!). I love helping start-ups identify and translate pain-points into scalable solutions.',
        '💌 Get in touch to have a chat about all things start-up/scale-up life, peopleX and internal ops and if you just want to share and trade experiences!',
        '💼 Interested in working with me? Get in touch directly!',
        'Advocate for #diversity #inclusion #mentalhealth #well-being #psychologicalsafety 🏳️‍🌈🤍'
    ],
    sections: [
        {
            type: 'column',
            columns: [
                {
                    type: 'list',
                    title: 'Skills',
                    items: [
                        'Communication',
                        'Collaboration',
                        'Coaching',
                        'Creativity',
                        'Empathy',
                        'Intellectual Curiosity',
                        'Proactivity',
                        'Relationship & Rapport Building',
                        'Resilience',
                        'Solution-oriented',
                        'Strategic Thinking',
                        'Thought Leadership',
                    ]
                },
                {
                    type: 'list',
                    title: 'Knowledge',
                    items: [
                        'Communications',
                        'Continuity & Contingency Planning',
                        'Diversity, Equality, Inclusion & Belonging',
                        'Employment Law & Best Practice',
                        'Engagement',
                        'Mental Health & Wellbeing',
                        'Organisational Design & Restructures',
                        'Performance',
                        'Succession Planning',
                        'Remote & Hybrid Working',
                        'Retention',
                        'Talent Acquisition',
                    ]
                }
            ]
        },
        {
            type: 'section',
            title: 'Experience',
            entries: [
                {
                    org: 'Patchwork Health',
                    orgDescription: 'Scaling HealthTech building innovative end-to-end workforce management solutions to enable flexible and sustainable staffing for the NHS.  ',
                    startDate: new Date('2022-09-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'Director of People, Talent & Internal Operations',
                    content: [
                        'Collaborating with the existing team and Founders to secure a sustainable and scalable future for Patchwork. Building on existing people ops and talent infrastructure to ensure maximum productivity and to provide a world-class people experience. ',
                    ]
                },
                {
                    org: 'Leslie',
                    orgDescription: 'Global FinTech providing an innovative payment technology to hospitality vendors.',
                    startDate: new Date('2021-10-01T00:00:00Z'),
                    endDate: new Date('2022-06-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'VP of People Ops & Talent',
                    content: [
                        'Embedding Leslie\'s vision and mission to attract, motivate and retain top-quality talent. Building sustainable people and talent infrastructure to ensure maximum efficiency and get ahead of any operational debt whilst scaling.',
                    ]
                },
                {
                    org: 'Kiwi Power',
                    orgDescription: 'Global EnerTech SME providing innovative energy management solutions through smart grid technology.',
                    startDate: new Date('2017-06-01T00:00:00Z'),
                    endDate: new Date('2021-10-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'Head of People Ops & Talent',
                    type: '🎉 Promotion',
                    content: [
                        'Building an awesome team and providing an environment for success throughout three leadership transitions and the associated cultural and strategic changes and challenges.',
                        '📋 Main role objectives: Building the People function and developing the People Strategy. From global talent acquisition through the entire employee cycle; and internal ops through continually improving performance and comfort by providing an environment for success and smooth, flexible operational infrastructure.',
                        '✅ Delivered the global people strategy putting people first.',
                        '👷🏼‍♀️ Executing the recruitment of brilliant individuals, hiring roles from graduate to senior leadership - creating and leading the recruitment and selection process and overseeing the whole talent acquisition process.',
                        '👷🏼‍♀️ Implementing ways to attract, retain and motivate top-quality talent and maximising team value by developing skills and identifying group strengths and best-in-class talent acquisition.',
                        '👷🏼‍♀️ Curating deliberate sustainable and scalable people and ops solutions through iteration and evolution based on feedback and performance to meet team needs and growth plans.',
                        '👷🏼‍♀️ Delivering organisational value through well-managed change and scalable redesign.',
                        '👷🏼‍♀️ Collaborating to design and maintain business contingency, continuity and succession plans; to manage and control risk; to identify and optimise opportunities; and overseeing CSR.',
                        '👷🏼‍♀️ Leading crisis management and implementing contingencies during business impact events.',
                        '🤓 Resident nerd on compliance with law and best people practice across the talent mix (incl. IR35).',
                        '✅ Implemented flexible-working improving productivity, motivation and empowerment (led the team into a full remote-working model due to COVID-19 and back into a hybrid model).',
                        '✅ Modernised and digitalised outdated, bureaucratic systems and processes through performance tooling and an agile and flexible approach to working practices, processes and policies.',
                        '✅ Established scaleable and suitable operations to host and support international employees. Jurisdictions: USA multi-state and Germany.',
                        '💡 Elevated well-being and mental health - designed the well-being strategy and open support ecosystem via third party providers and internal coaching including mental health first aid.',
                        '💡 Brought diversity, equality and inclusion to the table for safe, proactive and open discussion and implemented a team-run D&I Steering Group with a forum for feedback; baseline metrics; and the foundation of a DEI strategy.',
                        '💡 Tackled the elephant in the room: engagement. Started the conversation on engagement and established a baseline for both psychological safety in giving feedback and team engagement. ',
                    ]
                },
                {
                    startDate: new Date('2016-09-01T00:00:00Z'),
                    endDate: new Date('2017-06-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'Internal Ops & HR Manager',
                    content: [
                        '🖍 Career shift to expand knowledge and increase exposure to larger scale business management, operations and scaling people practice.',
                        '📋 Main role objectives: Kiwi Power sought a solution-driven operations manager to improve the team\'s working environment and employee experience and to design and build scalable people operations. Collaborate with the leadership team on core business management (people management and talent acquisition; ops and infrastructure; and business planning, change, continuity and risk). Identify and translate pain-points into tangible solutions.',
                        '👷🏼‍♀ Began curating the internal People and Talent function - created suitable, sustainable and agile policies and processes.',
                        '✅ Ensured a smooth and fun environment - oversaw team and premises operations; championed a social and exciting workplace; looked out for team well-being; and provided hassle-free experiences to internals and externals. ',
                        '✅ Enhanced team communication and performance - implemented and promoted collaborative performance and knowledge sharing tooling and general systems improvement; and improved the onboarding experience. ',
                        '✅ Relocated the team to more suitable space to meet growth plans and foster a collaborative flexible environment (hybrid).',
                        '✅ Achieved certification of and designed an externally audited combined management system - ISO 45001, 9001, 27001.',
                    ]
                },
                {
                    org: 'Black Diamond Commodities Ltd',
                    orgDescription: 'European agri-business brokering and trading bulk feed materials, additives and speciality products.',
                    startDate: new Date('2015-06-01T00:00:00Z'),
                    endDate: new Date('2016-09-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'Sales & Operations Director',
                    type: '🎉 Appointed company director in 2015',
                    content: [
                        '📋 Main role objectives: Maintain infrastructure for growth by implementing new ways of working as the business grew and expanded the team and continue supporting subsidiaries and expand the partner portfolio. Lead sales plans across subsidiary companies.',
                        '✅ Led sales strategies - in the agri-business side of the group delivered 100% opportunity to sale conversion and 100% client retention for partners with continued year-on-year revenue growth at 50%-100%.',
                        '✅ Improved company performance through positioning, pricing, sales and account management; and reduced financial risk by managing overheads and supply chain.',
                        '👷🏼‍♀ Expanded people and internal operations - staging and set-up for compliant payroll, pension; implemented leave and time tracking for shift and hourly paid staff; and creating general workplace and People processes and ways of working.',
                    ]
                },
                {
                    startDate: new Date('2013-07-01T00:00:00Z'),
                    endDate: new Date('2015-06-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'Trading & Operations Manager',
                    type: '🎉 Promotion',
                    content: [
                        '📋 Main role objectives: Managing existing and new entities by implementing sales and logistics processes. Actively sell products for all businesses. Onboard and mentor new team members.',
                        '📈 Expanded agri-business portfolio with niche products and developed existing and new business for exclusive partners.',
                        '📈 Collaboratively developed and scaled new side-line subsidiary businesses (removals sector).',
                        '✅ Flexibly adapted and responded to fast-paced change and growth including first hires and a new business acquisition.',
                        '✅ Implemented a scalable and sustainable parent company infrastructure and working practices to support growth - expanding on existing infrastructure to support continued business development including People practice and recruitment and internal ops.',
                        '✅ Relocation due to new business acquisition to meet new warehousing and manufacturing activities.',
                        '🤓 Compliance with contractual and legal requirements.'
                    ]
                },
                {
                    startDate: new Date('2012-06-01T00:00:00Z'),
                    endDate: new Date('2013-07-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'Trainee Trader & Operations Assistant',
                    type: ['🖍 Internship', '🎉 Confirmed full-time permanent role after 3 months'],
                    content: [
                        '📋 Main role objectives: As a start-up BDC Ltd required a hands-on individual to take on a pivotal and broad role - a combination of sales / trading (agricultural commodities and speciality feed products) and business operations and management. Be the exclusive executive representative for a French animal feed producer. Full assistance to the MD to aid the acquisition and retention of other exclusive partnerships under portfolio management.',
                        '📈 Direct sales and trading on behalf of portfolio partners - building relationships with existing clients and forging new ones; and trading spot, future and channel distributor contracts of bulk and speciality animal feed products. Full sales life-cycle - developing leads; contract signing; delivery; and continued account management / customer service.',
                        '👷🏼‍♀️ Occupational infrastructure and internal ops - financial admin and banking (incl. VAT and forex); premises and information management; comms and tech ops; and anything and everything the business needed to operate smoothly.',
                        '💻 EA to MD: <ul><li>General business support.</li><li>Scheduling and diary management.</li><li>Travel arrangements.</li><li>Itinerary planning (business context).</li></ul>',
                    ]
                },
                {
                    org: 'University of Essex',
                    startDate: new Date('2011-09-01T00:00:00Z'),
                    endDate: new Date('2012-07-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'Financial Admin Assistant',
                    school: '🏢 School of Health and Human Science',
                    type: ['👩🏼‍🎓 Part-time (while studying)', '🎉 Asked to stay on after the end of the Summer Placement'],
                    content: [
                        '📋 Main role objectives: Clear data back-log and implement an efficient archiving system and Assistant to the departmental Financial Administrator.',
                        '💻 Financial admin assistant: <ul><li>Raised and processed financial artefacts.</li><li>Processed T&E expenses.</li><li>Assisted with student events.</li><li>Processed student collections. </li><li>Organised CRB checks.</li></ul>',
                        '✅ Implemented new processes and a database for future file storage and archiving.',
                    ]
                },
                {
                    startDate: new Date('2011-06-01T00:00:00Z'),
                    endDate: new Date('2011-09-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'Marketing Frontrunner & Marketing & Communications PA',
                    type: '☀️ Summer Placement',
                    school: '🏢 School of Health and Human Sciences ',
                    content: [
                        '📋 Main role objectives: Market research into prospective CPD for healthcare professionals to enhance the current course portfolio (multi-campus) and Assistant to the departmental Marketing & Communications Administrator.',
                        '🏃🏼‍♀️ Market Research Frontrunner: <ul><li>Created a database of market data.</li><li>Designed and distributed a mail-shot questionnaire to identify the requirement for additional courses.</li><li>Conducted feedback sessions with current cohorts.</li></ul>',
                        '💻 Marketing & Communications PA: <ul><li>Event organisation (conferences and open days).</li><li>Prospectus and factsheet editing and mock-ups.</li><li>Scheduling and diary management.</li><li>Internal and external communications.</li><li>General admin.</li></ul>',
                    ]
                },
                {
                    startDate: new Date('2009-09-01T00:00:00Z'),
                    endDate: new Date('2011-06-01T00:00:00Z'),
                    tags: [],
                    jobTitle: 'Student Ambassador',
                    school: '🏢 Students Union ',
                    type: '👩🏼‍🎓 Term-time',
                    content: [
                        '🚌 Conducting campus tours and providing insight to prospective applicants.',
                    ]
                },
                {
                    org: 'Hospitality & Retail',
                    startDate: new Date('2006-09-01T00:00:00Z'),
                    endDate: new Date('2012-07-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'Various part-time and summer jobs throughout tertiary education. Full details available on <a href="https://www.linkedin.com/in/leannealicewalsham">LinkedIn</a>.',
                    ]
                },
            ]
        },
        {
            type: 'column',
            columns: [
                {
                    type: 'section',
                    title: 'Education',
                    entries: [
                        {
                            org: '',
                            school: 'University of Essex',
                            jobTitle: 'BSc Psychology',
                            startDate: new Date('2008-09-01T00:00:00Z'),
                            endDate: new Date('2012-06-01T00:00:00Z'),
                            dateFormat: 'year',
                            content: []
                        },
                        {
                            org: '',
                            jobTitle: 'GCSEs & A Levels',
                            school: 'St. Alban\s High School & Sixth Form',
                            startDate: new Date('2001-09-01T00:00:00Z'),
                            endDate: new Date('2008-06-01T00:00:00Z'),
                            dateFormat: 'year',
                            content: []
                        }
                    ]
                },
                {
                    type: 'row',
                    rows: [
                        {
                            type: 'section',
                            title: 'Accreditations',
                            entries: [
                                {
                                    jobTitle: 'Associate CIPD',
                                    info: 'Level 5 Diploma in HR Management',
                                    content: []
                                },
                            ]
                        },
                        {
                            type: 'list',
                            title: 'People Tech',
                            items: [
                                'BreatheHR',
                                'CIPHR',
                                'HiBob',
                                'LinkedIn + Recruiter',
                                'TeamTailor',
                                'Workable',
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'section',
            title: 'Learning & Development',
            entries: [
                {
                    startDate: new Date('2022-01-01T00:00:00Z'),
                    endDate: new Date('2022-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'Fire Marshal Re-qualification / Refresher &nbsp; <b>|</b> &nbsp; St. John’s Ambulance',
                        'First Aid at Work Re-qualification / Refresher &nbsp; <b>|</b> &nbsp; St. John’s Ambulance',
                    ]
                },
                {
                    startDate: new Date('2021-01-01T00:00:00Z'),
                    endDate: new Date('2021-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'Internal Communications &nbsp; <b>|</b> &nbsp; Institute of Internal Communications',
                        'Workplace Well-being &nbsp; <b>|</b> &nbsp; Welfy'
                    ]
                },
                {
                    startDate: new Date('2020-01-01T00:00:00Z'),
                    endDate: new Date('2020-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'Data Science Bootcamp (short course) &nbsp; <b>|</b> &nbsp; Udemy',
                        'Remote Worker Health and Well-being (short course) &nbsp; <b>|</b> &nbsp; Udemy',
                        'Diversity, Equality and Inclusion Training &nbsp; <b>|</b> &nbsp; Korn Ferry',
                        'Fire Marshal Re-qualification / Refresher &nbsp; <b>|</b> &nbsp; St. John’s Ambulance',
                        'First Aid at Work Re-qualification / Refresher &nbsp; <b>|</b> &nbsp; St. John’s Ambulance'
            ]
                },
                {
                    startDate: new Date('2019-01-01T00:00:00Z'),
                    endDate: new Date('2019-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'Conflict Resolution (short course) &nbsp; <b>|</b> &nbsp; Udemy',
                        'Adult Mental Health First Aid &nbsp; <b>|</b> &nbsp; Mental Health England',
                        'CQI IRCA ISO 27001:2013 (Information Security) Foundation Course &nbsp; <b>|</b> &nbsp; Alcumus',
                        'CQI IRCA 45001:2018 (Occupational Health & Safety) Internal Auditor Course &nbsp; <b>|</b> &nbsp; Alcumus',
                        'First Aid at Work Re-qualification / Refresher &nbsp; <b>|</b> &nbsp; St. John’s Ambulance',
                        'Fire Marshal Refresher &nbsp; <b>|</b> &nbsp; St. John’s Ambulance'
                    ]
                },
                {
                    startDate: new Date('2018-01-01T00:00:00Z'),
                    endDate: new Date('2018-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'CQI IRCA ISO 9001:2015 (Quality) Internal Auditor Course &nbsp; <b>|</b> &nbsp; Alcumus',
                        'Mindfulness & Well-being &nbsp; <b>|</b> &nbsp; Future Learn',
                        'IOSH Working Safely &nbsp; <b>|</b> &nbsp; St. John’s Ambulance',
                        'First Aid at Work (3-days) &nbsp; <b>|</b> &nbsp; St. John’s Ambulance',
                        'Fire Marshal Re-qualification / Refresher &nbsp; <b>|</b> &nbsp; St. John’s Ambulance'
                    ]
                },
                {
                    startDate: new Date('2017-01-01T00:00:00Z'),
                    endDate: new Date('2017-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'CQI IRCA ISO 9001:2015 (Quality) Foundation Training Course &nbsp; <b>|</b> &nbsp; Alcumus',
                        'Financial Management and Administration &nbsp; <b>|</b> &nbsp; Udemy',
                        'Emergency First Aid at Work (1-day) &nbsp; <b>|</b> &nbsp; St. John’s Ambulance',
                        'Fire Marshal &nbsp; <b>|</b> &nbsp; St. John’s Ambulance'
                    ]
                },
                {
                    startDate: new Date('2016-01-01T00:00:00Z'),
                    endDate: new Date('2016-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'K2 London Trading Technical Analysis &nbsp; <b>|</b> &nbsp; Hosted by BCFTA',
                        'Feed Production &nbsp; <b>|</b> &nbsp; BCFTA',
                    ]
                },
                {
                    startDate: new Date('2015-01-01T00:00:00Z'),
                    endDate: new Date('2015-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'Sales and Management &nbsp; <b>|</b> &nbsp; Hosted by BCFTA',
                        'Leadership and Management &nbsp; <b>|</b> &nbsp; Future Learn',
                        'IOSH Managing Occupational Health and Safety &nbsp; <b>|</b> &nbsp; RoSPA'
                    ]
                },
                {
                    startDate: new Date('2014-01-01T00:00:00Z'),
                    endDate: new Date('2014-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'Health & Safety Competent Person &nbsp; <b>|</b> &nbsp; Worksafe UK',
                        'Advanced Nutrition and Health of Farm Animals &nbsp; <b>|</b> &nbsp; Hosted by BCFTA'
                    ]
                },
                {
                    startDate: new Date('2013-01-01T00:00:00Z'),
                    endDate: new Date('2013-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'Nutrition and Health of Farm Animals &nbsp; <b>|</b> &nbsp; Hosted by BCFTA',
                        'Prince 2 Practitioner &nbsp; <b>|</b> &nbsp;  ILX Group',
                        'Emergency First Aid at Work &nbsp; <b>|</b> &nbsp; St. John’s Ambulance'
                    ]
                },
                {
                    startDate: new Date('2012-01-01T00:00:00Z'),
                    endDate: new Date('2012-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'Trade Awareness &nbsp; <b>|</b> &nbsp; BCFTA',
                        'MSO Essentials'
                    ]
                },
                {
                    startDate: new Date('2011-01-01T00:00:00Z'),
                    endDate: new Date('2011-01-01T00:00:00Z'),
                    dateFormat: 'year',
                    tags: [],
                    content: [
                        'ECDL &nbsp; <b>|</b> &nbsp; Hosted by University of Essex',
                        'Brand Awareness &nbsp; <b>|</b> &nbsp; Hosted by University of Essex',
                        'Digital Marketing Basic Training &nbsp; <b>|</b> &nbsp; Hosted by University of Essex'
                    ]
                },
            ]
        }
    ]
}
